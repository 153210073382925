<template>
  <div class="page-container">
    <div class="page-route-select">
      <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
        Home
      </router-link>
      <span class="material-icons page-route-select-icon">play_arrow</span>
      <div class="page-route-select-text">
        Manutenção de Produtos
      </div>
    </div>
    <div class="page-title">
      Manutenção de Produtos
    </div>
    <div class="products-page__actions-container">
      <div class="products-page__left-content">
        <div class="products-page__datetime-container">
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="products-page__datetime-title">Data Cadastro</div>
            </div>
            <div class="page-filters__right-select-wrapper">
              <datetime class="datetime-input form-control" v-model="filteredData.registerDate" ref="datepicker" auto type="date"></datetime>
              <span v-if="!filteredData.registerDate" @click="getFilteredList" class="material-icons date-icon">
                today
              </span>
              <span v-if="filteredData.registerDate" @click="getFilteredList" class="material-icons date-icon">
                search
              </span>
            </div>
          </div>
        </div>
        <div className="products-page__allproducts-checkbox-container">
          <div>
            <div v-if="!isLastBuyListChecked" @click="filterLastBuyDateList" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Apenas produtos comprados</span>
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-if="isLastBuyListChecked" @click="removeLastBuyDateList" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Apenas produtos comprados</span>
                <input type="checkbox" checked>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div>
            <div v-if="!isProPadraoNullChecked" @click="filterProPadraoNull" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Produtos sem pro padrão</span>
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-if="isProPadraoNullChecked" @click="removeProPadraoNull" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Produtos sem pro padrão</span>
                <input type="checkbox" checked>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="products-page__allproducts-checkbox-container">
          <div>
            <div v-if="!isGenericProductsChecked" @click="filterGenericProductsOnly" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Apenas produtos genéricos</span>
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-if="isGenericProductsChecked" @click="removeGenericProductsOnly" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Apenas produtos genéricos</span>
                <input type="checkbox" checked>
                <span class="checkmark"></span>
              </label>
            </div>
          </div>
          <div>
            <div v-if="!isObjectiveProductsChecked" @click="filterObjectiveProductsOnly" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Apenas produtos objetivos</span>
                <input type="checkbox">
                <span class="checkmark"></span>
              </label>
            </div>
            <div v-if="isObjectiveProductsChecked" @click="removeObjectiveProductsOnly" class="lastbuydate_wrapper">
              <label class="products-page__checkbox-wrapper">
                <span class="lastbuydate_title">Apenas produtos objetivos</span>
                <input type="checkbox" checked>
                <span class="checkmark"></span>
              </label>
            </div>
         </div>
       </div>
       <div class="products-page__allproducts-checkbox-container">
        <div>
          <div v-if="!isBrandsOnlyChecked" @click="filterBrandsOnly" class="lastbuydate_wrapper">
            <label class="products-page__checkbox-wrapper">
              <span class="lastbuydate_title">Apenas produtos com marcas</span>
              <input type="checkbox">
              <span class="checkmark"></span>
            </label>
          </div>
          <div v-if="isBrandsOnlyChecked" @click="removeBrandsOnly" class="lastbuydate_wrapper">
            <label class="products-page__checkbox-wrapper">
              <span class="lastbuydate_title">Apenas produtos com marcas</span>
              <input type="checkbox" checked>
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
       </div>
      </div>
      <div class="products-page__right-content">
        <Button class="action-buttons" title="Pesquisar" :text="'Pesquisar'" :iconName="'search'" :action="getFilteredList"/>
        <Button class="action-buttons" title="Alterar Produto" :text="'Alterar Produto'" :iconName="'edit'" :action="openEditMultipleGtinModal"/>
        <Button class="action-buttons" title="Limpar Filtros" :text="'Limpar Filtros'" :iconName="'cancel'" :action="clearFilters"/>
      </div>
    </div>
    <div class="products-page__inputs-container">
      <StandardInput :placeholder="'Digite o nome do produto'" 
                     :title="'Descrição'" 
                     :action="setProductValue" 
                     :value="filteredData.productName"
                     class="products-page__inputs-input"
                     :type="'text'"/>
      <StandardInputListFilteredAuto :selectWidth="'24.6vw'"
                                     class="products-page__inputs-input"
                                     title="Cliente" 
                                     :propPlaceholder="selectClient" 
                                     :action="setCompany"
                                     :list="clientsList"
                                     :actionTwo="changeSelect"
                                     :type="'select'"/>
      <StandardInput :placeholder="'Digite o código ean'" 
                     :title="'Código Ean'" 
                     :action="setEanCodeValue" 
                     :value="filteredData.eanCode"
                     class="products-page__inputs-input"
                     :type="'text'"/>
      <StandardInput :placeholder="'Digite o código gtin'" 
                     :title="'Código Gtin'" 
                     :action="setGtinCodeValue" 
                     :value="filteredData.gtinCode"
                     class="products-page__inputs-input"
                     :type="'text'"/>
    </div>
    <div class="page-selected-filters-containear">
	    <div v-if="filteredData.productName" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filteredData.productName }}</div>
        <span @click="setProductValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filteredData.clientName" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filteredData.clientName }}</div>
        <span @click="setClientValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filteredData.eanCode" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filteredData.eanCode }}</div>
        <span @click="setEanCodeValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
      <div v-if="filteredData.gtinCode" class="page-selected-filters">
        <div class="page-selected-filters-text">{{ filteredData.gtinCode }}</div>
        <span @click="setGtinCodeValue('')" class="material-icons page-selected-filters-icon">close</span>
      </div>
    </div>
    <cc-loader v-show="isLoading" />
    <no-data-available v-if="productsList.length == 0 && !isLoading " :text="'Nenhum produto encontrado'" />
    <div v-show="productsList.length > 0 &&!isLoading && !showTable" class="page-table-container-product">
      <div>
        <div class="page-table-header">
          <div class="page-table-header-text checkbox-width">
            <div class="page-table-header-mobile"></div>
            <span class="material-icons-outlined checkbox-icon" 
                  v-if="!allProductsIsChecked" 
                  @click="selectAllProducts" 
                  style="color: var(--primary-color);">
              check_box_outline_blank
            </span>
            <span v-else
                  class="material-icons-outlined checkbox-icon"
                  @click="unSelectAllProducts" 
                  style="color: var(--primary-color);">
              check_box
            </span>
          </div>
          <div class="page-table-header-text ean-code-width">Ean/Prod Padrão</div>
          <div class="page-table-header-text gtin-code-width">Código Gtin</div>
          <div class="page-table-header-text product-name-width">Nome Produto/Marca</div>
          <div class="page-table-header-text client-width">Cliente</div>
          <div class="page-table-header-text last-date-width">Data da Última Compra</div>
          <div class="page-table-header-text edit-width">Ações</div>
        </div>
        <div v-for="(product, idx) in productsList" 
             :key="idx">
          <div :class="getSpecialBackground(idx)" class="page-table-row-containear">
            <div class="page-table-row">
              <div v-if="product.isChecked" class="page-table-desc-column checkbox-width">
                <div class="page-table-header-mobile" ></div>
                <span class="material-icons-outlined checkbox-icon" style="color: var(--primary-color);" @click="removeMultipleGtinCode(product)">check_box</span>
              </div>
              <div v-else class="page-table-desc-column checkbox-width">
                <div class="page-table-header-mobile" ></div>
                <span class="material-icons-outlined checkbox-icon" style="color: var(--primary-color);" @click="storeMultipleGtinCode(product)">check_box_outline_blank</span>
              </div>
              <div class="page-table-desc-column ean-code-width"><div class="page-table-header-mobile">Código Ean</div>{{ product.pro_ean }} <br>{{ product.pro_padrao_id || '--'}}</div>
              <div class="page-table-desc-column gtin-code-width">
              <div class="page-table-header-mobile">Código Gtin</div>
              {{ product.pro_gtin || '-' }} <br>
              {{ getProductType(product.PTYPE) }}
              </div>
              <div class="page-table-desc-column product-name-width"><div class="page-table-header-mobile">Nome e Produto</div>{{ product.pro_descricao }} <br><span v-if="product.pro_observacao" class="product-brand-text">{{ product.pro_observacao }}</span></div>
              <div class="page-table-desc-column client-width"><div class="page-table-header-mobile">Cliente</div>{{ product.cli_nome }}</div>
              <div class="page-table-desc-column last-date-width"><div class="page-table-header-mobile">Data da Última Compra</div>{{ product.pro_dataUltimaCompra | formatDate }}</div>
              <div class="page-table-desc-column edit-width">
                <div class="page-table-header-mobile">Ações</div>
                <span @click="showEditGtin(product)" class="material-icons add-seller-btn">
                  edit
                </span>
                <span v-if="product.pro_padrao_id_brand && product.pro_principal && product.pro_generico == 0" 
                      @click="showEditProBrand(product)" 
                      class="material-icons-outlined settings-icon">
                  settings
                </span>
              </div>
            </div>
          </div>
        </div>
        <tr style="display: flex; !important" v-show="total_items > items_by_page_value">
          <td style="margin: 0 auto;" colspan="5">
            <Paginacao classes="orange justify-end"
                       :items_by_page_value="items_by_page_value"
                       :total="total_items"
                       :page="page"
                       :page_limit='7'
                       v-on:change="getFilteredList($event)">
            </Paginacao>
           </td>
        </tr>
      </div>
    </div>
    <EditMultipleGtinModal v-if="showEditMultipleGtinModal"
                           :closeDialog="closeEditMultipleGtinModal"
                           :productsId="multipleGtinCodeStorage"
                           @reload="reload"/>
    <EditGtinModal v-if="showEditGtinModal"
                   :closeDialog="closeEditGtinModal"
                   :productId="gtinProductId.id"
                   :product="gtinProductValue"
                   @reload="reload"/>
    <EditProBrandModal v-if="isEditProBrandOpen"
                       :closeDialog="closeEditProBrandModal"
                       :product="proBrandValue"
                       @reload="reload"/>
  </div>
</template>
<script>

import Paginacao from "@/components/cliente/base-components/Pagination";
import ProductService from '@/services/v3/management/product.service';
import CategoryService from '@/services/v3/management/category.service';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import Button from '@/components/ui/buttons/StandardButton.vue'
import axios from 'axios'
import * as CONSTANTS from '../constants/constants'
import EditMultipleGtinModal from '../modals/EditMultipleGtinModal.vue'
import EditGtinModal from "../modals/EditGtinModal.vue";
import StandardSearch from '@/components/ui/inputs/StandardSearch.vue'
import { Datetime } from 'vue-datetime';
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputListFilteredAuto from "../components/ui/inputs/StandardInputListFilteredAuto.vue";
import EditProBrandModal from "../modals/edit-pro-brand/EditProBrand.modal"

export default {
    mixins: [ loaderMixin, typingRemoteRequestControlMixin ],
    components: {
    Paginacao,
    Button,
    EditMultipleGtinModal,
    EditGtinModal,
    StandardSearch,
    Datetime,
    StandardInputListFiltered,
    StandardInput,
    StandardInputListFilteredAuto,
    EditProBrandModal
},
    data(){
        return {
            allProductsIsChecked: false,
            clientsList: [],
            selectClient: 'Selecione o cliente',
            arraySelectLoja: [],
            productsList: [],
            showEditMultipleGtinModal: false,
            showEditGtinModal: false,
            gtinProductId: {
              id: undefined,
            },
            gtinProductValue: undefined,
            filteredData: {
              productName: undefined,
              clientName: undefined,
              clientId: undefined,
              eanCode: undefined,
              gtinCode: undefined,
              registerDate: undefined,
              lastBuyDate: undefined,
            },
            multipleGtinCodeStorage: [],
            minDate: moment().toDate().toISOString(),
            loading: false,
            isLoading: false,
            showTable: false,
            isLastBuyListChecked: false,
            page: 1,
            products: [],
            filters: {},
            categories: [],
            items_by_page_value: 30,
            total_items: 0,
            brands: null,
            svc: new ProductService(),
            cat_svc: new CategoryService(),
            show_add_product_modal: false,
            product_pattern: true,
            options: [
                {
                    label: "Novo Produto",
                    action: () => { this.show_add_product_modal = true }
                }
            ],
            breadcrumbs: [
                {
                    name: "Produtos Padrão",
                    route: 'products'
                }
            ],
            lastBuyDateFilterValue: null,
            proGenericoValue: 0,
            proPadraoNullValue: 0,
            isTotalProductsChecked: false,
            isGenericProductsChecked: false,
            isObjectiveProductsChecked: false,
            isProPadraoNullChecked: false,
            isBrandsOnlyChecked: false,
            isEditProBrandOpen: false,
            proBrandValue: undefined,
        }
    },
    methods: {
      closeEditProBrandModal: function() {
        this.isEditProBrandOpen = false;
      },
      getProductType(value) {
        if (value == 'GENERIC') return 'Generico'
        else if (value == 'BRAND') return 'Marca'
        else if (value == 'OBJECTIVE') return 'Objetivo'
        else return '--'
      },
      showEditProBrand(product) {
        this.proBrandValue = product;
        this.isEditProBrandOpen = true;
      },
      filterProPadraoNull() {
        this.isProPadraoNullChecked = true;
        this.proPadraoNullValue = 1
      },
      removeProPadraoNull() {
        this.isProPadraoNullChecked = false;
        this.proPadraoNullValue = 0;
      },
      filterBrandsOnly() {
        this.isBrandsOnlyChecked = true;

        this.proGenericoValue = 3;
      },
      removeBrandsOnly() {
        this.isBrandsOnlyChecked = false;

        this.proGenericoValue = 0;
      },
      filterGenericProductsOnly() {
        this.isGenericProductsChecked = true;
        this.isObjectiveProductsChecked = false;

        this.proGenericoValue = 1
      },
      removeGenericProductsOnly() {
        this.isGenericProductsChecked = false;
        this.isObjectiveProductsChecked = false;

        this.proGenericoValue = 0;
      },
      filterObjectiveProductsOnly() {
        this.isGenericProductsChecked = false;
        this.isObjectiveProductsChecked = true;

        this.proGenericoValue = 2;
      },
      removeObjectiveProductsOnly() {
        this.isGenericProductsChecked = false;
        this.isObjectiveProductsChecked = false;

        this.proGenericoValue = 0;
      },
      selectAllProducts(){
        this.allProductsIsChecked = true

        this.productsList.forEach(item => {
          if (item.isChecked == undefined || item.isChecked == false) {
            item.isChecked = true
            this.$forceUpdate()
          }
        })
      },
      unSelectAllProducts() {
        this.allProductsIsChecked = false

        this.productsList.forEach(item => {
          if (item.isChecked == true) {
            item.isChecked = false
            this.$forceUpdate()
          }
        })
        
      },
      changeSelect(value) {
        if (!value) return
        this.filteredData.clientName = value
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.requestClients(value)
          },1500)
        }
      },
      requestClients(){
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            name: this.filteredData.clientName,
          },
        };
        axios
          .get(`${CONSTANTS.API_V3_URL}/admin/clients?page=1&sort=l.loj_razao`, config)
          .then(data => {
            this.loading = false
            this.clientsList = []
            data.data.data.forEach(element => {
                this.clientsList.push({
                  text: element.name,
                  value: element
                });
            });
            this.$forceUpdate()
          });
        },
      setCompany(value) {
        this.filteredData.clientName = value.name
        this.filteredData.clientId = value.id
      },
      reload() {
        this.getFilteredList()
        this.$forceUpdate()
      },
      filterLastBuyDateList() {
        this.isLastBuyListChecked = true
        this.lastBuyDateFilterValue = 1
      },
      removeLastBuyDateList() {
        this.isLastBuyListChecked = false
        this.lastBuyDateFilterValue = null
      },
      storeMultipleGtinCode(product) {
        product.isChecked = true
        this.$forceUpdate()
      },
      removeMultipleGtinCode(product) {
        product.isChecked = false
        this.$forceUpdate()
      },
      openEditMultipleGtinModal() {
        this.multipleGtinCodeStorage = []
        this.productsList.forEach(item => {
            if (item.isChecked) {
              this.multipleGtinCodeStorage.push(item.pro_id)
            }
          })
        if (this.multipleGtinCodeStorage.length == 0) {
          this.$swal.fire("Você precisa selecionar pelo menos um produto!")
        } else {
          this.showEditMultipleGtinModal = true
        }
      },
      getProductsList() {
        this.isLoading = true
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        axios
          .get(`${CONSTANTS.API_V3_URL}/admin/management/products/product?page=1&pro_desc&cli_nome&pro_ean&pro_DataCadastro&order_by=cli_nome&pro_gtin`, config)
          .then(data => {
            this.productsList = data.data.data
            this.total_items = data.data.total
            this.isLoading = false
          });
      },
      getFilteredList(page = this.page) {
        this.isLoading = true
        this.arraySelectLoja = []
        const config = {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          params: {
            pro_desc: this.filteredData.productName,
            //cli_nome: this.filteredData.clientName,
            cli_id: this.filteredData.clientId,
            pro_ean: this.filteredData.eanCode,
            pro_gtin: this.filteredData.gtinCode, 
            pro_DataCadastro: this.filteredData.registerDate,
            pro_dataUltimaCompra: this.lastBuyDateFilterValue,
            pro_padrao_id_null: this.proPadraoNullValue,
            pro_generico: this.proGenericoValue,
            page: page,
          },
        };
        axios
          .get(`${CONSTANTS.API_V3_URL}/admin/management/products/product?page=${page}&pro_desc&cli_nome&pro_ean&pro_DataCadastro&order_by=cli_nome&pro_gtin`, config)
          .then(data => {
            data.data.data.forEach(element => {
              this.arraySelectLoja.push({
                text: element.cli_nome,
                value: element
              })
            })
            this.productsList = data.data.data
            this.total_items = data.data.total
            this.isLoading = false
          });
      },
      setProductValue(value){
		    this.filteredData.productName = value
		    this.getFilteredList()
		  },
      setClientValue(value) {
		    this.filteredData.clientName = value
        this.filteredData.clientId = value
		    this.getFilteredList()
      },
      setEanCodeValue(value) {
		    this.filteredData.eanCode = value
		    this.getFilteredList()
      },
      setGtinCodeValue(value) {
		    this.filteredData.gtinCode = value
		    this.getFilteredList()
      },
      clearFilters() {
        this.isLoading = true
        this.filteredData.productName = ""
        this.filteredData.clientName = ""
        this.filteredData.eanCode = ""
        this.filteredData.gtinCode = ""
        this.filteredData.registerDate = ""
        this.getProductsList()
      },
      showEditGtin(product) {
        this.gtinProductValue = product;
        this.gtinProductId.id = product.pro_id;
        this.showEditGtinModal = true;
      },
      closeEditMultipleGtinModal: function() {
        this.showEditMultipleGtinModal = false;
      },
	    closeEditGtinModal: function() {
        this.showEditGtinModal = false;
      },
      getSpecialBackground (idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        toggle_highlight(val, product) {
            this.svc.highlight(product.id, val).then(() => {
                product.is_highlighted = val
            })
        },
    },
    mounted() {
      this.$store.dispatch('set_breadcrumbs', this.breadcrumbs);
    }
}
</script>

<style lang="scss" scoped>
.page-container {
  font-size: 1vw;
  margin-right: 4em;
}
.page-route-select {
  display: flex;
  align-items: center;
}
.page-route-select-text {
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon {
  color: var(--primary-color);
  margin-right: 20px;
}
.page-title {
  font-weight: 600;
  font-size: 2.72em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #605F5F;
  margin-top: 45px;
}
.products-page__actions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3em 0em;
}
.action-buttons {
  margin-left: 1em;
}
.products-page__inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0em;
}
.products-page__inputs-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.products-page__inputs-input{
  width: 25vw;
}
.products-page__inputs {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 0.5em;
  width: 21vw;
  height: 6vh;
}
.products-page__inputs:focus {
  border: 1px solid #ff7110 !important;
}
.products-page__checkbox-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products-page__checkbox-wrapper {
  display: flex;
}
/* The container */
.products-page__checkbox-wrapper {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #898989;
}

/* Hide the browser's default checkbox */
.products-page__checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
  border: 2.5px solid #ff7110;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.products-page__checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.products-page__checkbox-wrapper input:checked ~ .checkmark {
  background-color: var(--primary-color);
  border: 1px solid #ff7110;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.products-page__checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.products-page__checkbox-wrapper .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.page-table-container-product {
  margin: 2em 0em;
}
.page-table-header {
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  display: flex;
  padding: 1em 0em;
}
.page-table-header-text {
  padding-left: 10px;
  font-weight: 600; 
  font-size: 1.19em;
  color: #505050;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  font-size: 1.0em;
  color: #605F5F;
}
.page-table-desc-column {
  padding: 0.5em 0.7em;
}
.checkbox-width {
  width: 3%;
}
.ean-code-width {
  width: 12.5%;
}
.gtin-code-width {
  width: 12.5%;
}
.product-name-width {
  width: 32%;
}
.package-width {
  width: 8%;
}
.client-width {
  width: 18.5%;
}
.last-date-width {
  width: 12.5%;
}
.edit-width {
  width: 8%;
}
.add-seller-btn {
  cursor: pointer;
}
.page-table-header-mobile{
  display:none
}
.page-selected-filters-containear {
  display: flex;
  margin: 1em 0em;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}
.products-page__datetime-title {
  font-size: 1.7em;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.datetime-input {
  border-radius: 8px !important;
}
.lastbuydate_wrapper {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-left: 20px;
}
.page-table-line-special {  
  background: #F7F7F7;
}
.products-page__left-content {
  display: flex;
  align-items: center;
}
.products-page__right-content {
  display: flex;
  align-items: center;
}
.product-brand-text {
  background-color: #2AD0621A !important;
  color: #187C3B !important;
  padding: 2px;
}
.checkbox-icon {
  cursor: pointer;
}
.products-page__allproducts-checkbox-container {
  display: flex;
  flex-direction: column;
}
.lastbuydate_title {
  position: relative;
  top: 3px;
}
.settings-icon {
  margin-left: 10px;
  color: var(--primary-color);
  cursor: pointer;
}

@media only screen and (max-width: 1000px){
  .page-table-header{
    display: none;
  }
  .page-container {
    font-size: 1.5vw;
    margin-right: 5vw;
  }
  .page-table-row{
    flex-direction: column;
    font-size: 1.2em;
  }
  .page-table-desc-column{
    display: flex;
    justify-content: space-between;
    text-align: end;
  }
  .checkbox-width {
    width: 100%;
  }
  .ean-code-width {
    width: 100%;
  }
  .gtin-code-width {
    width: 100%;
  }
  .product-name-width {
    width: 100%;
  }
  .package-width {
    width: 100%;
  }
  .client-width {
    width: 100%;
  }
  .last-date-width {
    width: 100%;
  }
  .edit-width {
    width: 100%;
  }
  .page-table-header-mobile{
    display: block;
    font-weight: 600;
    font-size: 1.1em;
    color: #505050;
    text-align: left;
  }
}
@media only screen and (max-width: 800px) {
  .page-container {
    font-size: 2.5vw;
  }
  .products-page__inputs-container{
    flex-direction: column;
    gap: 2vh;
  }
  .products-page__inputs-input{
    width: 100%;
  }
  .products-page__actions-container{
    flex-direction: column;
    gap: 2vh;
  }
  .products-page__right-content{
    flex-direction: column;
    width: 100%;
    gap: 2vh;
  }
  .products-page__left-content{
    flex-direction: column;
    gap: 2vh;
  }
  .action-buttons{
    margin-left: 0;
    width: 100%;
  }
  .page-filters__right-select-wrapper{width: 100vw;}
  .lastbuydate_wrapper{padding-top: 0;}
}
@media only screen and (max-width: 550px) {
  .page-container {
    font-size: 3.5vw;
  }
}
</style>